import React from 'react'
import { FaFacebookSquare, FaInstagram, FaYoutube, FaLinkedin, FaTwitter } from "react-icons/fa"
import Responsive from '../Components/Responsive'
import Logo from "../Assets/Logo.png"



const socialLinks = [{
  name: "Facebook",
  icon: <FaFacebookSquare />,
  path: ""
}, {
  name: "Twitter",
  icon: <FaTwitter />,
  path: ""
}, {
  name: "LinkedIn",
  icon: <FaLinkedin />,
  path: ""
}, {
  name: "Youtube",
  icon: <FaYoutube />,
  path: ""
}, {
  name: "Instagram",
  icon: <FaInstagram />,
  path: ""
}]

const Footer = () => {
  return (
    <>
      <div className="bg-[rgb(248,249,250)]">
        <footer className={`${Responsive} md:py-5 lg:py-5 py-5 sm:py-5  mx-auto `}>
          <div className="flex sm:flex-row flex-col gap-y-3 items-center justify-between w-full">
            <h4 className='font-semibold text-lg '>
              <img src={Logo} alt="logo-footer" className='sm:w-36 w-32' />
            </h4>
            <div className="">
              &copy;copyright <u className='cursor-pointer text-orange-400'>ZoopedAi.com</u>
            </div>
            <div className="flex  items-center gap-3">
              {socialLinks.map((items, index) => {
                return <span key={index} className="flex gap-1 md:w-10 w-8 h-8 md:h-10 bg-white justify-center text-orange-400 rounded-full shadow-lg hover:underline items-center cursor-pointer"> <span className=' text-lg md:text-xl hover:text-2xl '>{items.icon}</span></span>
              })}
            </div>
          </div>

        </footer>
      </div>


    </>
  )
}

export default Footer