import React from 'react'
import Brands from '../Components/Brands'
import Cases from '../Components/Cases'
import Faqs from '../Components/Faqs'
import Features from '../Components/Features'
import GetInTouch from '../Components/GetInTouch'
import Hero from '../Components/Hero'
import HowToUse from '../Components/HowToUse'
import Needs from '../Components/Needs'
import Steps from '../Components/Steps'
import Tabs from '../Components/Tabs'

const Home = ({ handleSwitch }) => {
    return (
        <div>
            <Hero handleSwitch={handleSwitch} />
            <Brands handleSwitch={handleSwitch} />
            <Features handleSwitch={handleSwitch} />
            <Steps handleSwitch={handleSwitch} />
            <HowToUse handleSwitch={handleSwitch} />
            <Cases handleSwitch={handleSwitch} />
            <Tabs/>
            <Needs/>
            <Faqs handleSwitch={handleSwitch} />
            <GetInTouch/>
        </div>
    )
}

export default Home