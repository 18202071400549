import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import OrangeButton from './Buttons/OrangeButton'
import { toast } from 'react-toastify';

const Form = () => {
    const form = useRef()

    const handleSubmit = (event) => {
        event.preventDefault()
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {
                result.status === 200 && toast.success("Request Sumbitted !")
            }, (error) => {
                console.log(error.text);
            });
        event.target.reset()
    }
    return (
        <form ref={form} onSubmit={handleSubmit} className='flex flex-col gap-5' >
            <div className="text-blue-600 font-semibold">About you</div>
            <div className="flex flex-col gap-1">
                <label htmlFor="fname" className='text-sm font-medium'>First Name</label>
                <input type="text" required name='fname' id='fname' placeholder='e.g. John' className="border  outline-none focus:border-2 focus:border-[rgb(249,142,64)] bg-white  rounded py-1.5 px-2.5" />
            </div>
            <div className="flex flex-col gap-1">
                <label htmlFor="lname" required className='text-sm font-medium'>Last Name</label>
                <input type="text" name='lname' id='lname' placeholder='e.g. Doe' className="border  outline-none focus:border-2 focus:border-[rgb(249,142,64)] bg-white  rounded py-1.5 px-2.5" />
            </div>
            <div className="flex flex-col gap-1">
                <label htmlFor="phone" required className='text-sm font-medium'>Phone Number</label>
                <input type="tel" name='phone' id='phone' className="border  outline-none focus:border-2 focus:border-[rgb(249,142,64)] bg-white  rounded py-1.5 px-2.5" />
            </div>

            <div className="flex flex-col gap-1">
                <label htmlFor="email" required className='text-sm font-medium'>Email</label>
                <input type="email" name='email' id='email' placeholder='e.g. abc@gmail.com' className="border  outline-none focus:border-2 focus:border-[rgb(249,142,64)] bg-white  rounded py-1.5 px-2.5" />
            </div>

            <div className="text-blue-600 font-semibold">About your company</div>
            <div className="flex flex-col gap-1">
                <label htmlFor="companyname" className='text-sm font-medium'>Company ( or project) name</label>
                <input type="text" required name='companyname' id='companyname' placeholder='e.g. Company' className="border  outline-none focus:border-2 focus:border-[rgb(249,142,64)] bg-white  rounded py-1.5 px-2.5" />
            </div>
            <div className="flex flex-col gap-1">
                <label htmlFor="companysize" className='text-sm font-medium'>Company Size</label>
                <input type="text" required name='companysize' id='companysize' className="border  outline-none focus:border-2 focus:border-[rgb(249,142,64)] bg-white  rounded py-1.5 px-2.5" />
            </div>
            <div className="flex flex-col gap-2">
                <label htmlFor="jobtitle" className='text-sm font-medium'>Job Title</label>
                <div className="grid grid-cols-2 gap-1">
                    <div className="flex items-center gap-2">
                        <input type="radio" required name='jobtitle' value="Entrepreneur" id='Entrepreneur' />
                        <label htmlFor="Entrepreneur" className='text-sm font-medium'>Entrepreneur</label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input type="radio" required name='jobtitle' value="Director" id='Director' />
                        <label htmlFor="Director" className='text-sm font-medium'>Director</label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input type="radio" required name='jobtitle' value="Student" id='Student' />
                        <label htmlFor="Student" className='text-sm font-medium'>Student</label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input type="radio" required name='jobtitle' value="Manager" id='Manager' />
                        <label htmlFor="Manager" className='text-sm font-medium'>Manager</label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input type="radio" required name='jobtitle' value="C-level" id='C-level' />
                        <label htmlFor="C-level" className='text-sm font-medium'>C-level</label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input type="radio" required name='jobtitle' value="Other" id='Other' />
                        <label htmlFor="Other" className='text-sm font-medium'>Other</label>
                    </div>
                </div>

            </div>

            <div className="text-blue-600 font-semibold">About your idea</div>
            <div className="flex flex-col gap-2">
                <label htmlFor="youridea" className='text-sm font-medium'>Stage you idea</label>
                <div className="grid  gap-1">
                    <div className="flex items-center gap-2">
                        <input type="radio" required name='youridea' value="I don't yet have an idea" id='y' />
                        <label htmlFor="y" className='text-sm font-medium'>I don't yet have an idea</label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input type="radio" required name='youridea' value="Just Exploring and need more information" id='j' />
                        <label htmlFor="j" className='text-sm font-medium'>Just Exploring and need more information</label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input type="radio" required name='youridea' value="  I have an idea but I'm not sure where to start" id='h' />
                        <label htmlFor="h" className='text-sm font-medium'>
                            I have an idea but I'm not sure where to start</label>
                    </div>
                    <div className="flex items-center gap-2">
                        <input type="radio" required name='youridea' value="  I'm ready to build my idea now" id='i' />
                        <label htmlFor="i" className='text-sm font-medium'>
                            I'm ready to build my idea now</label>
                    </div>
                </div>
            </div>
            <button type="submit">
                <OrangeButton title="Watch zoopedAi.com demo" />
            </button>

            <div className='text-sm'>
                By proceeding you agree to Builder.ai's <u> privacy policy </u>  and <u>terms and conditions</u>
            </div>
        </form>
    )
}

export default Form