import React from "react";
import OrangeButton from "../Components/Buttons/OrangeButton";
import CommonHero from "../Components/CommonHero";
import Responsive from "../Components/Responsive";

const indutriesData = [
    {
        title: "Fintech",
        icon: "https://cdn-icons-png.flaticon.com/512/4453/4453608.png",
    },
    {
        title: "Logistics",
        icon: "https://cdn-icons-png.flaticon.com/512/4363/4363531.png",
    },
    {
        title: "Oil & Gas",
        icon: "https://cdn-icons-png.flaticon.com/512/344/344723.png",
    },
    {
        title: "Security",
        icon: "https://cdn-icons-png.flaticon.com/512/508/508281.png",
    },
    {
        title: "Fitness and sport ",
        icon: "https://cdn-icons-png.flaticon.com/512/3018/3018598.png",
    },
    {
        title: "Consumer Services",
        icon: "https://cdn-icons-png.flaticon.com/512/5935/5935717.png",
    },
    {
        title: "Hospitality & travel",
        icon: "https://cdn-icons-png.flaticon.com/512/5333/5333434.png",
    },
    {
        title: "Media & entertainments",
        icon: "https://cdn-icons-png.flaticon.com/512/2065/2065064.png",
    },
    {
        title: "Real States",
        icon: "https://cdn-icons-png.flaticon.com/512/1209/1209504.png",
    },
    {
        title: "Financial Services",
        icon: "https://cdn-icons-png.flaticon.com/512/1077/1077976.png",
    },
    {
        title: "Education",
        icon: "https://cdn-icons-png.flaticon.com/512/70/70035.png",
    },
    {
        title: "E-commerce & Retail",
        icon: "https://cdn-icons-png.flaticon.com/512/2991/2991406.png",
    },
    {
        title: "Telecoms",
        icon: "https://cdn-icons-png.flaticon.com/512/2560/2560120.png",
    },
    {
        title: "Health Care",
        icon: "https://cdn-icons-png.flaticon.com/512/2966/2966334.png",
    },
    {
        title: "Construction",
        icon: "https://cdn-icons-png.flaticon.com/512/1606/1606208.png",
    },
    {
        title: "Automation",
        icon: "https://cdn-icons-png.flaticon.com/512/9075/9075702.png",
    },
];

const Industries = ({ handleSwitch }) => {
    return (
        <>
           <CommonHero backImg={'https://images.unsplash.com/photo-1647343137860-69ccd12a61c6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'} mainTitle="Industries" subTitle="We Serve"/>
            {/* <div className="text-3xl  font-semibold text-center lg:text-4xl">
                   All Industries 
                </div> */}
            <div className={`${Responsive} `}>
                <div className="grid md:grid-cols-3  sm:grid-cols-2 lg:grid-cols-3 gap-10">
                    {indutriesData.map((items) => {
                        return (
                            <div className="flex flex-col items-center justify-center w-full mx-auto">
                                <div className="w-full flex justify-center items-center h-64 bg-gray-300 p-5 bg-no-repeat bg-center rounded-lg shadow-md">
                                    <img src={items.icon} alt="" className="w-28 h-28" />
                                </div>

                                <div className="w-56 -mt-6 py-1 overflow-hidden bg-white rounded-lg shadow-lg md:w-64 ">
                                    <h3 className="py-2 font-bold tracking-wide text-center text-gray-800 uppercase ">
                                        {items.title}
                                    </h3>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="mb-10 flex justify-center">
                <span onClick={handleSwitch}>
                    <OrangeButton title={"Get Started"} />
                </span>
            </div>
        </>
    );
};

export default Industries;
