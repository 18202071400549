import React from "react";
import { useState } from "react";
import Responsive from "./Responsive";
import WithOutBorder from "../Components/Buttons/WithOutBorder";

const Tabs = () => {
  const [active, setActive] = useState("0");
  const tabLinks = [
    {
      title: "Entreprenuer",
      path: "0",
    },
    {
      title: "Small Business",
      path: "1",
    },
    {
      title: "Enterprise",
      path: "2",
    },
  ];

  const Cards = () => {
    switch (active) {
      case "0":
        return (
          <div className="grid items-center w-full my-12 md:grid-cols-2 gap-10">
            <div className="flex flex-col gap-4 lg:pr-10">
              <h3 className="text-3xl font-semibold">
              Make your dream business app a reality
              </h3>
              <p className="text-lg">
              Our product experts are with you every step of the way to project manage your custom app build. Zero tech knowledge needed and you own everything, including your code.
              </p>
              <button className="self-start py-2.5 px-5 bg-orange-400 rounded text-white">
                Learn More
              </button>
            </div>
            <div className=" rounded shadow-md flex md:flex-row flex-col  bg-orange-400">
              <div className="p-10 ">
                <figure className="mb-5">
                  <img src="https://www.builder.ai/images/moodit-logo-white.png" alt="" className="w-40" />
                </figure>
                <figure className="mb-4">
                  <img src="https://www.builder.ai/images/Path-2.svg" alt="" />
                </figure>
                <p className="text-white">
                The project has felt like it is in excellent hands. The team has been thoroughly responsive, engaged and genuinely interested and motivated to make the project the absolute best it can be.
                </p>
                <div className="flex flex-col text-white text-sm tracking-wide  my-5">
                  <span className="font-semibold">Dr Hassan Yasin</span>
                  <span>Founder of Moodit</span>
                </div>
                <button className="border-b-2 flex gap-1 items-center text-white hover:bg-white hover:text-orange-500 px-1.5 border-white  rounded  font-medium ">
                Read moodit case study
                </button>
              </div>
              <img
                src="https://www.builder.ai/images/mobile-phone-in-hand-having-moodit-app-opened.png"
                alt=""
                className="md:w-64 w-full md:h-auto h-32 object-cover  border-2 rounded-tl-[90px] md:rounded-tl-[130px] border-black"
              />
            </div>
          </div>
        );

      case "1":
        return (
          <div className="grid items-center w-full my-12 md:grid-cols-2 gap-10">
            <div className="flex flex-col gap-4 lg:pr-10">
              <h3 className="text-3xl font-semibold">
              Scale your sales with an app you own
              </h3>
              <p className="text-lg">
              Fed up of revenue sharing schemes and marketplace fees? Then create your own piece of digital real estate, where you keep all your profits. Plus you own the code, meaning your app can scale alongside your business.
              </p>
              <button className="self-start py-2.5 px-5 bg-orange-400 rounded text-white">
                Learn More
              </button>
            </div>
            <div className=" rounded shadow-md flex md:flex-row flex-col  bg-orange-400">
              <div className="p-10 ">
                <figure className="mb-5">
                  <img src="https://www.builder.ai/images/eat-at-denbys-logo-white.png" alt="" className="w-40" />
                </figure>
                <figure className="mb-4">
                  <img src="https://www.builder.ai/images/Path-2.svg" alt="" />
                </figure>
                <p className="text-white">
                I loved the fact that the app and website is an asset to my business. There are no commission charges, the payment plan to pay for the app and website was affordable and simple, with no hidden charges and the product is fantastic.
                </p>
                <div className="flex flex-col text-white text-sm tracking-wide  my-5">
                  <span className="font-semibold">Louise</span>
                  <span>Founder of Eat@Denby’s</span>
                </div>
                <button className="border-b-2 flex gap-1 items-center text-white hover:bg-white hover:text-orange-500 px-1.5 border-white  rounded  font-medium ">
                  Read moodit case study
                </button>
              </div>
              <img
                src="https://www.builder.ai/images/eat-at-denbys-store-front.png"
                alt=""
                className="md:w-64 w-full md:h-auto h-32 object-cover  border-2 rounded-tl-[90px] md:rounded-tl-[130px] border-black"
              />
            </div>
          </div>
        );

      case "2":
        return (
          <div className="grid items-center w-full my-12 md:grid-cols-2 gap-10">
            <div className="flex flex-col gap-4 lg:pr-10">
              <h3 className="text-3xl font-semibold">
                Bypass your internal teams and get apps built fast
              </h3>
              <p className="text-lg">
                We build apps for you with unlimited customisations and you get
                total visibility on your project from beginning to end. We also
                throw in an app prototype you can share with internal teams and
                investors, and you own everything, including your code.
              </p>
              <button className="self-start py-2.5 px-5 bg-orange-400 rounded text-white">
                Learn More
              </button>
            </div>
            <div className=" rounded shadow-md flex md:flex-row flex-col  bg-orange-400">
              <div className="p-10 ">
                <figure className="mb-5">
                  <img src="https://www.builder.ai/images/g10-1.svg" alt="" className="w-40" />
                </figure>
                <figure className="mb-4">
                  <img src="https://www.builder.ai/images/Path-2.svg" alt="" />
                </figure>
                <p className="text-white">
                  Builder.ai has been a valuable partner of Makro for over 2
                  years, allowing us to create our own proprietary platform upon
                  which we have built our entire eCommerce presence.
                </p>
                <div className="flex flex-col text-white text-sm tracking-wide  my-5">
                  <span className="font-semibold">Dhruv Kumar Singh</span>
                  <span>Technical Lead, Siam Makro</span>
                </div>
                <button className="border-b-2 flex gap-1 items-center text-white hover:bg-white hover:text-orange-500 px-1.5 border-white  rounded  font-medium ">
                  Read moodit case study
                </button>
              </div>
              <img
                src="https://www.builder.ai/images/makro-store-front.png"
                alt=""
                className="md:w-64 w-full md:h-auto h-32 object-cover  border-2 rounded-tl-[90px] md:rounded-tl-[130px] border-black"
              />
            </div>
          </div>
        );

      default:
        break;
    }
  };
  return (
    <div className={`${Responsive}`}>
      {" "}
      <div className="flex flex-col items-center ">
        <h3 className="text-3xl mb-5 font-semibold max-w-xl text-center lg:text-4xl">
          An app platform for every type of business
        </h3>
        <div className="flex gap-10 justify-center text-sm items-center">
          {tabLinks.map((item) => {
            return (
              <span
                onClick={() => setActive(item.path)}
                className={`hover:text-orange-500 ${
                  item.path === active && " border-b-[2px] text-orange-500"
                } border-orange-500 hover:border-b-[2px] font-medium cursor-pointer`}
              >
                {item.title}
              </span>
            );
          })}
        </div>
        {Cards()}
      </div>
    </div>
  );
};

export default Tabs;
