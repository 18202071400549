import React from 'react'
import Responsive from './Responsive'

const stepsItem = [
    {
        heading: "Get matched to an expert",
        description: "They'll project manage everything - from kick-off to successful delivery."
    },
    {
        heading: "Tell them what you need",
        description: "You'll get expert advice and a free prototype. Plus a fixed price and set deadline."
    },
    {
        heading: "AI assembles your app",
        description: "Choose features and our AI fits them together (like Lego blocks) to form your app."
    },
    {
        heading: "Customised by specialists",
        description: "Designers and developers tailor your features to make them uniquely yours."
    },
]

const Steps = () => {
    return (
        <div id='works'>
            <section className={` ${Responsive} text-center `}>
                <div>The <span className='underline cursor-pointer'> ZoopedAi.Com</span> commitment</div>
                <div>Pricing is understandable and correlates exactly to your requirements</div>
                <button className='py-2.5 px-5 rounded border-2 mt-7 font-semibold hover:bg-[rgb(230,232,236)]'>
                    See how pricing works
                </button>

            </section>

            <section className='bg-slate-50 py-8 md:py-10'>
                <h2 className='font-semibold text-center text-2xl sm:text-3xl md:text-4xl'>How it works in 4 simple steps</h2>
                <div className={`grid md:grid-cols-3 grid-cols-2  lg:grid-cols-4 gap-6 md:gap-12 ${Responsive} `}>

                    {
                        stepsItem.map((items, index) => {
                            return <div className="" key={index}>
                                <span className="text-orange-400 bg-white shadow-xl py-1.5 px-3 sm:py-2 sm:px-4 text-xl rounded-full">{index + 1}</span>
                                <div className='text-orange-400 pb-2 pt-4 md:pt-5 border-b-2 border-orange-400 text-lg'>{items.heading}</div>
                                <div className='mt-4 tracking-wider '>{items.description}</div>
                            </div>
                        })
                    }

                </div>
            </section>
        </div>
    )
}

export default Steps