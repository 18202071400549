import React from 'react'
import WithOutBorder from './Buttons/WithOutBorder'
import CommonGS from './CommonGS'
import Responsive from './Responsive'

const HowToUse = ({handleSwitch}) => {
    return (
        <div className='mt-14'>
            <CommonGS handleSwitch={handleSwitch} subheading="How we use artificial intelligence" />
            <div className={`${Responsive} flex flex-col md:gap-32`}>
                {/* 1 */}
                <div className="md:grid flex flex-col-reverse md:grid-cols-2 md:gap-0 mb-20 gap-5 items-center md:px-10 lg:px-28">
                    <div className="md:relative ">
                        <div className="border md:absolute md:-top-14 lg:-top-20 xl:-top-28 md:-right-16 px-5 lg:px-10 bg-white py-7 lg:py-16 rounded shadow">
                            <h3 className='lg:text-3xl text-2xl font-semibold'>Hello</h3>
                            <div className='text-[rgb(98,0,234)] lg:text-3xl text-2xl font-bold'>Entrepreneur</div>
                            <div className='lg:py-5 md:text-base text-sm md:py-3 py-2'>You’ve found the most cost effective solution on the market. We bring your vision to life – exactly how you imagined. It’s easy, cost effective and you get expert support every step of the way to your successful launch.</div>
                            <WithOutBorder title="Learn More" />
                        </div>
                    </div>
                    <figure className=''>
                        <img src="https://www.builder.ai/images/Rectangle-9926-2.png" alt="" className='h-full object-cover' />
                    </figure>
                </div>
                {/* 2 */}
                <div className="grid md:grid-cols-2 md:gap-0 mb-20 gap-5 items-center md:px-10 lg:px-28">
                <figure className=''>
                        <img src="https://www.builder.ai/images/unsplash_bePoqwaPItg-2.png" alt="" className='h-full object-cover' />
                    </figure>
                    <div className="md:relative ">
                        <div className="border md:absolute md:-top-14 lg:-top-20 xl:-top-28 md:-left-16 px-5 lg:px-10 bg-white py-7 lg:py-16 rounded shadow">
                            <h3 className='lg:text-3xl text-2xl font-semibold'>Hello</h3>
                            <div className='text-[rgb(98,0,234)] lg:text-3xl text-2xl font-bold'>Small business owner</div>
                            <div className='lg:py-5 md:text-base text-sm md:py-3 py-2'>We’ve got your back. Now you can get the cost saving benefits of AI, without all the techie stuff (leave that to us). But you’ll stay in control throughout – from the free prototype to visualise what you’re getting before you start, to owning your code once you’ve finished.</div>
                            <WithOutBorder title="Learn More" />
                        </div>
                    </div>
                   
                </div>
                {/* 3 */}
                <div className="md:grid flex flex-col-reverse md:grid-cols-2 md:gap-0 mb-20 gap-5 items-center md:px-10 lg:px-28">
                    <div className="md:relative ">
                        <div className="border md:absolute md:-top-14 lg:-top-20 xl:-top-28 md:-right-16 px-5 lg:px-10 bg-white py-10 lg:py-16 rounded shadow">
                            <h3 className='lg:text-3xl text-2xl font-semibold'>Hello</h3>
                            <div className='text-[rgb(98,0,234)] lg:text-3xl text-2xl font-bold'>Enterprises</div>
                            <div className='lg:py-5 md:text-base text-sm md:py-3 py-2'>We can build your apps at speed. There’s no training required or any learning curve, as our specialists handle everything – from finding the right solution to delivering it fast. And you own everything, including your code.</div>
                            <WithOutBorder title="Learn More" />
                        </div>
                    </div>
                    <figure className=''>
                        <img src="https://www.builder.ai/images/unsplash_faEfWCdOKIg-2.png" alt="" className='' />
                    </figure>
                </div>
            </div>
        </div>
    )
}

export default HowToUse