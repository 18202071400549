import React from "react";
import CommonHero from "../Components/CommonHero";
import Responsive from "../Components/Responsive";

const servicesData = [
    {
        img: "https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8bW9iaWxlJTIwYXBwfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
        title: "Mobile app development",
        desc: "As proven app developers, we empower end to end mobile solutions- both native and hybrid, regarding your goals, budget, and timelines. In terms of each and every project, we primarily use trusted professional mobile development frameworks, libraries, and the variety of tools."
    },
    {
        img: "https://images.unsplash.com/photo-1580983559367-0dc2f8934365?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHNvZnR3YXJlJ3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        title: "Software development ",
        desc: "Software development is the process of conceiving, specifying, designing, programming, documenting, testing, and bug fixing involved in creating and maintaining applications, frameworks, or other software components. "
    },
    {
        img: "https://images.unsplash.com/photo-1542744095-291d1f67b221?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8d2VifGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
        title: "Web development",
        desc: "Web development is the work involved in developing a website for the Internet or an intranet. Web development can range from developing a simple single static page of plain text to complex web applications, electronic businesses, and social network services."
    },
    {
        img: "https://images.unsplash.com/photo-1592503254549-d83d24a4dfab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8ZWNvbW1lcmNlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
        title: "E commerce development",
        desc: "Ecommerce website development is the process of developing the technical aspects of your ecommerce website — how it functions, accepts payments, behaves in response to user action and appears aesthetically. "
    },
    {
        img: "https://images.unsplash.com/photo-1557838923-2985c318be48?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8ZGlnaXRhbCUyMG1hcmtldGluZ3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        title: "Digital marketing",
        desc: "Digital marketing is the component of marketing that uses the Internet and online based digital technologies such as desktop computers, mobile phones and other digital media and platforms to promote products and services. "
    },
    {
        img: "https://images.unsplash.com/photo-1487014679447-9f8336841d58?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8d2ViJTIwZGVzaWduaW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
        title: "Web designing ",
        desc: "Web design encompasses many different skills and disciplines in the production and maintenance of websites. The different areas of web design include web graphic design; user interface design; authoring, including standardised code and proprietary software; user experience design; and search engine optimization"
    },
    {
        img: "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8ZGF0YSUyMGFuZCUyMGJpfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
        title: "Data & BI ",
        desc: "There is a lot of data in web documents and services. This means that the application of data mining techniques on these can help one discover useful information. It can help a business discover meaningful insights to understand its customers, products, and operations better. The business owners can then take the necessary measures to make corrections and improvements where necessary. This is good for the smooth running and growth of any business."
    },
    {
        img: "https://images.unsplash.com/photo-1507146153580-69a1fe6d8aa1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MnwxNTYwNTk4fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
        title: "AI & ML",
        desc: "Artificial intelligence is intelligence—perceiving, synthesizing, and infering information—demonstrated by machines, as opposed to intelligence displayed by animals and humans."
    },
    {
        img: "https://images.unsplash.com/photo-1573205485246-ee99bb898ff6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8c3VwcGx5JTIwY2hhaW58ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
        title: "Supply chain",
        desc: "A supply chain is the network of all the individuals, organizations, resources, activities and technology involved in the creation and sale of a product. A supply chain encompasses everything from the delivery of source materials from the supplier to the manufacturer through to its eventual delivery to the end user."
    },
    {
        img: "https://images.unsplash.com/photo-1668854096784-3ce7679fa841?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGRldm9wc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        title: "DevOps",
        desc: "DevOps is the combination of cultural philosophies, practices, and tools that increases an organization's ability to deliver applications and services at high velocity: evolving and improving products at a faster pace than organizations using traditional software development and infrastructure management processes."
    },
    {
        img: "https://images.unsplash.com/photo-1558346490-a72e53ae2d4f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aW90fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
        title: "IOT",
        desc: "The Internet of things describes physical objects with sensors, processing ability, software and other technologies that connect and exchange data with other devices and systems over the Internet or other communications networks"
    },
    {
        img: "https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8YnVzaW5lc3MlMjBhdXRvbWF0aW9ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
        title: "Business automation",
        desc: "Business automation is a term for the use of technology applications that perform repetitive tasks, freeing up employees for higher value work. This includes business process automation (BPA), robotic process automation (RPA) and AI-powered automation."
    }
]



const Services = ({ handleSwitch }) => {
    return (
        <>
            <header>

                <CommonHero backImg={'https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'} mainTitle="Our" subTitle="Services"/>
                
              

                <div
                    className={`${Responsive} `}
                >
                    <p className="pb-8 md:pb-10 text-center">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deleniti provident odit, perferendis neque suscipit dolore rem alias natus vero accusamus quaerat placeat culpa voluptatum adipisci blanditiis molestias exercitationem, ipsa dolores eos illo vitae voluptatem dolorem fugit totam. Exercitationem quas placeat aspernatur tempore sint nesciunt quis ipsa accusamus. </p>
                    <div className="grid md:grid-cols-3  sm:grid-cols-2 lg:grid-cols-3 gap-10">
                        {servicesData.map((items) => {
                            return (
                                <div className="w-full  overflow-hidden bg-white rounded-lg shadow-lg ">
                                    <img className="object-cover object-center w-full h-56" src={items.img} alt={items.title} />

                                    <div className="flex items-center px-6 py-3 bg-orange-400">
                                        <h1 className=" text-lg font-semibold text-white uppercase">{items.title} </h1>
                                    </div>

                                    <div className="px-6 py-4">
                                        <h1 className=" font-semibold text-gray-800 ">{items.title}</h1>

                                        <p className="py-2 text-gray-700 ">{items.desc.slice(0, 105)} {items.desc.length > 105 && "..."} </p>

                                        <button onClick={handleSwitch} className='rounded-full mt-2 shadow-sm py-2 sm:py-2.5 sm:px-5 px-4  hover:bg-orange-500 bg-orange-400 text-white tracking-wider font-medium   '>Contact Now</button>

                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {/* <div className="text-3xl mt-14  xl:text-5xl font-semibold text-center lg:text-4xl">
                    Industries We  <span className="text-orange-400">Serve</span>{" "}
                </div> */}


            </header>
        </>
    );
};

export default Services;
