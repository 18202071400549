import React from "react";
import OrangeButton from "./Buttons/OrangeButton";

const Hero = ({ handleSwitch }) => {
  return (
    <>
      <div
        className="w-full bg-center bg-cover   h-[18rem] md:h-[29rem]"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1553877522-43269d4ea984?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80')",
        }}
      >
        <div className="flex items-center justify-center w-full h-full bg-[rgb(0,0,0,0.6)]">
          <div className="text-center">
            <div class="text-center">
              <h1 class="text-3xl font-semibold text-white  lg:text-4xl">
                We make building software so easy, anyone can do it
              </h1>

              <p class="my-6 text-gray-100 ">
                Your vision. Your software. We just build it
              </p>
              <span className="" onClick={handleSwitch}>
                <OrangeButton title={"Get Started"} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
