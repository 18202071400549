import React from 'react'

const CommonHero = ({backImg,mainTitle,subTitle}) => {
  return (
    <div
    className="w-full bg-center bg-cover  h-[18rem] md:h-[29rem]"
    style={{
        backgroundImage:
            `url(${backImg})`,
    }}
>
    <div className="flex items-center justify-center w-full h-full bg-[rgb(0,0,0,0.6)]">
        <div className="text-center">
            <h1 className="text-3xl xl:text-5xl font-semibold text-white lg:text-4xl">
                <span className="text-orange-400">{mainTitle}</span> {subTitle}
            </h1>
        </div>
    </div>
</div>
  )
}

export default CommonHero