import React, { useRef } from "react";
import Responsive from "./Responsive";
import OrangeButton from "../Components/Buttons/OrangeButton";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const GetInTouch = () => {
  const form = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          result.status === 200 && toast.success("Request Sumbitted !");
        },
        (error) => {
          console.log(error.text);
        }
      );
    event.target.reset();
  };

  return (
    <div id="contact_us" className={`${Responsive} `}>
      <div className="flex flex-col items-center ">
        <h3 className="text-3xl mb-3 font-semibold  lg:text-4xl">Contact Us</h3>
        <div className="border-b-2 flex cursor-pointer gap-1 items-center hover:bg-[rgb(249,142,64)] px-1.5 border-[rgb(249,142,64)]  rounded  font-medium">
          Get In Touch
        </div>
      </div>

      <div className="flex gap-5 items-center py-10 ">
        <figure className="lg:w-3/5 md:w-1/2  md:flex hidden">
          <img
            src="https://images.unsplash.com/photo-1534536281715-e28d76689b4d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
            alt="contact us"
            className="w-full h-full object-cover rounded"
          />
        </figure>
        <form
          ref={form}
          onSubmit={handleSubmit}
          className="lg:w-2/5 md:w-1/2 w-full flex flex-col gap-4 p-5"
        >
          <div className="space-y-1">
            <label className="font-medium" htmlFor="name">
              Name
            </label>
            <input
              required
              name="name"
              className="w-full outline-none block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              type="text"
              id="name"
              placeholder="Enter your name"
            />
          </div>

          <div className="space-y-1">
            <label className="font-medium" htmlFor="email">
              Email
            </label>
            <input
              required
              className="w-full outline-none block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
            />
          </div>

          <div className="space-y-1">
            <label className="font-medium" htmlFor="Phone">
              Phone
            </label>
            <input
              required
              className="w-full outline-none block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              type="tel"
              id="Phone"
              name="Phone"
              placeholder="Enter your phone"
            />
          </div>

          <div className="space-y-1">
            <label className="font-medium" htmlFor="message">
              Message
            </label>
            <textarea
              required
              className="w-full outline-none block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-orange-500 focus:ring focus:ring-orange-500 focus:ring-opacity-50"
              id="message"
              name="message"
              placeholder="Enter your message"
            />
          </div>

          <button type="submit">
            <OrangeButton title="Submit" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default GetInTouch;
