import React, { useState } from "react";
import { CgMenu } from "react-icons/cg";
import Responsive from "../Components/Responsive";
import { IoMdClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../Assets/Logo.png";

const menuItems = [
  // {
  //     name: "Brands",
  //     path: "#brands"
  // },
  {
    name: "FAQ's",
    path: "#faqs",
  },
  {
    name: "Works",
    path: "#works",
  },

  {
    name: "Features",
    path: "#features",
  },
];

const Navbar = ({ handleSwitch }) => {
  const [mobileView, setMobileView] = useState(false);
  const navigate = useNavigate("/");

  const handleMenu = () => {
    setMobileView(!mobileView);
  };
  return (
    <>
      <section
        className="py-2  text-white sticky z-20 top-0
             left-0 bg-orange-400 shadow-sm"
      >
        <div
          className={`${Responsive} sm:py-1 md:py-1 lg:py-1 xl:py-1 py-0  flex items-center`}
        >
          {/* Logo */}
          <figure onClick={() => navigate("/")} className="cursor-pointer">
            <img src={Logo} alt="logo" className="md:w-52 w-40" />
          </figure>

          {/* Hamburger Icon */}
          <div
            onClick={handleMenu}
            className="ml-auto cursor-pointer text-2xl lg:hidden"
          >
            {mobileView ? <IoMdClose /> : <CgMenu />}
          </div>

          {/* Nav-Menus */}
          <div
            className={`flex lg:shadow-none shadow  lg:py-0 py-4 items-center transition-all duration-100 lg:flex-row flex-col lg:text-white text-black lg:bg-transparent bg-white  lg:pl-12  xl:pl-16  lg:static absolute ${
              mobileView ? "left-0" : "-left-full"
            } top-14  w-full`}
          >
            <div className="flex lg:flex-row flex-col gap-4 lg:gap-5">
              {menuItems.map((items, index) => {
                return window.location.pathname === "/" ? (
                  <a
                    href={items.path}
                    onClick={handleMenu}
                    key={index}
                    className="flex items-center cursor-pointer gap-1"
                  >
                    {items.name}{" "}
                  </a>
                ) : (
                  <Link
                    to="/"
                    onClick={handleMenu}
                    key={index}
                    className="flex items-center cursor-pointer gap-1"
                  >
                    {items.name}{" "}
                  </Link>
                );
              })}
              <Link
                to="/services"
                onClick={handleMenu}
                className="flex items-center cursor-pointer gap-1"
              >
                Services
              </Link>
              <Link
                to="/industries"
                onClick={handleMenu}
                className="flex items-center cursor-pointer gap-1"
              >
                Industries
              </Link>
              <Link
                to="/blogs"
                onClick={handleMenu}
                className="flex items-center cursor-pointer gap-1"
              >
                Blogs
              </Link>
              <Link
                to="/carrers"
                onClick={handleMenu}
                className="flex items-center cursor-pointer gap-1"
              >
                Carrers
              </Link>
            </div>
            <div className="flex lg:py-0 py-6 lg:flex-row flex-col flex-wrap gap-5 lg:ml-auto items-center">
              {
                window.location.pathname === "/" ? (
                  <a
                href="#contact_us"
                onClick={handleMenu}

                className="rounded-full shadow-sm py-2 sm:py-2.5 sm:px-5 px-4 bg-[#F98E40]  lg:bg-white lg:text-[#F98E40] tracking-wider font-medium text-white  "
              >
                Get A Demo
              </a>
                ) : (
                  <Link
                    to="/"
                    onClick={handleMenu}
                    className="rounded-full shadow-sm py-2 sm:py-2.5 sm:px-5 px-4 bg-[#F98E40]  lg:bg-white lg:text-[#F98E40] tracking-wider font-medium text-white  "
                  >
                     Get A Demo
                  </Link>)
              }
             
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
