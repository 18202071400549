import React from "react";
import WithOutBorder from "./Buttons/WithOutBorder";
import Responsive from "./Responsive";

const Brands = () => {
  return (
    <div div id="brands">
      <div className="bg-slate-50 pb-36 md:pb-28  ">
        <div className={`${Responsive}`}>
          <div className="text-center font-semibold text-xl md:text-2xl">
            Trusted by the world’s leading brands
          </div>
          <div className="flex justify-center flex-wrap py-6 gap-10 text-center">
            <img src="https://www.builder.ai/images/Pepsi_1.svg" alt="" />
            <img src="https://www.builder.ai/images/BBC_1.svg" alt="" />
            <img src="https://www.builder.ai/images/Virgin_1.svg" alt="" />
            <img src="https://www.builder.ai/images/NBC_1.svg" alt="" />
            <img src="https://www.builder.ai/images/Fujitsu_1.svg" alt="" />
            <img src="https://www.builder.ai/images/Makro_1.svg" alt="" />
          </div>
          <div className="flex justify-center w-full mt-4">
            <WithOutBorder title="See why they chose us >" />
          </div>
        </div>
      </div>

      <div
        className={`md:-mt-48 -mt-40 flex-wrap ${Responsive} flex justify-center gap-10 md:gap-16`}
      >
        {[
          "https://www.builder.ai/images/Makro_1.svg",
          "https://www.builder.ai/images/Virgin_1.svg",
          "https://www.builder.ai/images/BBC_1.svg",
        ].map((items) => {
          return (
            <div className=" hover:-translate-y-4 transition-all duration-150 cursor-pointer border md:w-80 py-16 w-full text-center text-sm gap-y-3 rounded p-8 flex-col flex items-center bg-white shadow-lg">
              <img src={items} alt="" />
              <p>
                ZoopedAi.Com solution was{" "}
                <span className="text-blue-500"> 98.3% cheaper</span> than
                paying previous ongoing SaaS costs
              </p>
            </div>
          );
        })}
      </div>

      <div className={`${Responsive} flex flex-col gap-y-16`}>
        {/* 1 */}
        <div className="grid md:grid-cols-2  items-center gap-5">
          <img
            src="https://www.builder.ai/images/Zebra_image-1.png"
            alt=""
            className="lg:px-20"
          />

          <div className="gap-2 lg:px-10 flex flex-col">
            <h3 className="md:text-4xl sm:text-3xl text-2xl font-semibold">
              Get Apps Built Fast with ZoopedAi
            </h3>
            {/* <span className="font-medium md:text-xl text-lg">
              Build anything you can imagine
            </span> */}
            <p className="pt-3">
              Get apps built quickly and easily with ZoopedAi. Our experienced
              engineers will work with you to create a custom app with total
              visibility and allow for any edits or changes. We build apps for
              all platforms, with a range of services and consultations for the
              best structure and quality standards. You own everything,
              including the code, with no hidden fees or restrictions. Our
              efficient and streamlined process delivers on time and within
              budget. Choose ZoopedAi for a cost-effective and efficient
              app-building solution. Contact us today to start building.
            </p>
          </div>
        </div>

        {/* 2 */}
        <div className="md:grid flex flex-col-reverse md:grid-cols-2  items-center gap-5">
          <div className="gap-2 lg:px-10 flex flex-col">
            <h3 className="md:text-4xl sm:text-3xl text-2xl font-semibold">
              YOUR APP, YOUR WAY
            </h3>
            {/* <span className="font-medium md:text-xl text-lg">
              AI means we can build your project quicker, and more cost
              effectively
            </span> */}
            <p className="pt-3">
              At ZoopedAi, get a custom business app tailored to meet your
              unique needs. Our team of experts will handle the complex aspects,
              ensuring efficient and streamlined app development. You'll have
              total project visibility and comprehensive support. Own the code
              and have full control over the app. Rely on us to deliver the app
              on time and within budget. Our experts will handle technical
              details, while you focus on running your business. Get an
              application designed to your specifications and features, and take
              control of your digital real estate today with ZoopedAi.
            </p>
          </div>
          <img
            src="https://www.builder.ai/images/Zebra_image-2-1.png"
            alt=""
            className="lg:px-20"
          />
        </div>

        {/* 3 */}
        <div className="grid md:grid-cols-2  items-center gap-5">
          <img
            src="https://www.builder.ai/images/Zebra_image-3-p-800.png"
            alt=""
            className="lg:px-20"
          />

          <div className="gap-2 lg:px-10 flex flex-col">
            <h3 className="md:text-4xl sm:text-3xl text-2xl font-semibold">
              Unlock New Revenue Streams With ZoopedAi
            </h3>
            {/* <span className="font-medium md:text-xl text-lg">
              We’re with you every step of the way
            </span> */}
            <p className="pt-3">
              Tired of high fees and revenue sharing from traditional
              marketplaces? Elevate your business with ZoopedAi, a custom app
              development company. Own the code, control the features and
              design, and keep all profits with no marketplace fees. ZoopedAi
              helps you create apps to sell your products and services,
              unlocking new revenue streams. Our apps are secure, reliable, and
              compliant with industry standards. Our team is available 24/7 for
              support and our marketing tools and analytics help optimize sales
              strategy. Take control of your business with ZoopedAi, create an
              app tailored to your unique needs, and watch your business grow.
              Get started now!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
