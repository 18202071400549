import React from "react";
import { GrFormClose } from "react-icons/gr";

import Form from "./Form";

const Popup = ({ handleClose, initialState }) => {
    return (
        <>
            <div
                className={`fixed z-40 top-0 ${initialState ? "right-0" : "-right-full"
                    }   transition-all duration-[10ms] w-full  h-screen bg-[rgb(0,0,0,0.5)]`}
            >
                <div className="md:w-3/5 lg:w-2/5 sm:w-2/3 overflow-y-auto h-full bg-[rgb(244,246,255)] ml-auto">
                    <div className="container mx-auto p-9 md:px-14">
                        <div
                            onClick={handleClose}
                            className="bg-white  absolute right-4  rounded-full border text-center w-8 h-8 cursor-pointer text-2xl flex justify-center  items-center"
                        >
                            <GrFormClose className="" />
                        </div>
                        <Form />


                    </div>
                </div>
            </div>{" "}
        </>
    );
};

export default Popup;
