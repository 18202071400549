import React from "react";
import Responsive from "../Components/Responsive";

const Needs = () => {
    const card = [
        {
            title:"Webs and apps ",
            img:"https://plus.unsplash.com/premium_photo-1661549678379-28f01d4892e1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
        },
        {
            title:"Small medium big",
            img:"https://images.unsplash.com/photo-1616792577902-f1d86383a21b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=703&q=80"
        },
        {
            title:"Custom web & apps",
            img:"https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
        }
    ]
    
  return (
    <div className={`${Responsive}`}>
      
      <h3 className="text-3xl mb-5 font-semibold  text-center lg:text-4xl">
        Solutions to fit your needs
      </h3>
      <div className="my-10 grid gap-8 sm:grid-cols-2 md:grid-cols-3">
        {card.map((item) => {
          return (
            <div
              href="#"
              className="sm:col-span-2 md:col-span-1 block group relative transition ease-out active:opacity-75 overflow-hidden"
            >
              <img
                src={item.img}
                alt={item.title}
                className="transform transition object-cover h-full w-full ease-out group-hover:scale-110"
              />
              <div className="absolute inset-0 bg-black bg-opacity-25 transition ease-out group-hover:bg-opacity-0" />
              <div className="p-4 flex items-center justify-center absolute inset-0">
                <div className="py-3 px-4 bg-white bg-opacity-95 rounded-3xl text-sm font-semibold uppercase tracking-wide transition ease-out group-hover:text-white group-hover:bg-blue-600">
                  {item.title}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Needs;
