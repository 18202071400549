import React from 'react'
import WithOutBorder from './Buttons/WithOutBorder'
import CommonGS from './CommonGS'
import Responsive from './Responsive'
import { SlScreenDesktop } from "react-icons/sl"
import { CgAppleWatch } from "react-icons/cg"
import { CiMobile4 } from "react-icons/ci"




const Cases = ({handleSwitch}) => {
    return (
        <>
            <section className={`${Responsive}    `}>
                <div className="md:grid-cols-2 items-center rounded shadow bg-[rgb(248,249,250)] grid gap-10 sm:p-7 p-5 md:p-10">
                    <div className="">
                        <h4 className='font-bold'>ZoopedAi.Com</h4>
                        <div className='text-blue-500 text-3xl font-medium py-3'>“A dramatically different approach”</div>
                        <span>2021 Gartner® Magic Quadrant™</span>
                    </div>
                    <div className="bg-white p-5 shadow rounded">
                        <div className='lg:text-xl sm:text-lg pb-3'>We were recognised as a Visionary in 2021 Gartner® Magic Quadrant™ for Multiexperience Development Platforms</div>
                        <div className="flex justify-end"><WithOutBorder title="Get the Gartner report" /></div>
                    </div>
                </div>
            </section>
            <CommonGS handleSwitch={handleSwitch} subheading="See solutions and pricing" />
            <div className='bg-[rgb(243,235,255)] mt-10 pb-36 md:pb-28  '>
                <div className={`${Responsive}`}>
                    <div className="text-center font-semibold text-xl md:text-2xl">Trusted by the world’s leading Cases</div>
                    <div className="text-center md:px-44 pt-3 md:text-lg">Looking for a no-code app builder? See how we’ve helped everyone from big global brands to innovative entrepreneurs to build software.</div>
                </div>
            </div>
            <div className={`md:-mt-48 -mt-40  ${Responsive} grid sm:grid-cols-2 lg:grid-cols-3   gap-10 md:gap-16`}>
                {["https://www.builder.ai/images/image-16-1.svg", "https://www.builder.ai/images/Rectangle-9921-2.svg", "https://www.builder.ai/images/g10-1.svg"].map((items) => {
                    return <div className="relative border  py-10 w-full text-sm gap-y-3 rounded-lg p-8 flex-col flex items-center bg-white shadow-lg">
                        <img src={items} alt="" />
                        <div className="text-3xl flex gap-10 items-center mt-5">
                            <SlScreenDesktop /><CiMobile4 /><CgAppleWatch />
                        </div>
                        <p className='pb-10 pt-5'>Child and adolescent psychiatrist, Dr Hassan Yasin, wanted to create a social media community for those facing mental health challenges. Our AI-powered platform helps people share their mood and find ways to improve it.</p>
                        <div className="py-6">
                            <WithOutBorder title="View case study " />
                        </div>
                        <div className='py-3  absolute bottom-0 left-0 rounded-b-lg bg-[rgb(103,107,126)] text-center text-white w-full'>Entrepeneur</div>
                    </div>
                })}

            </div>
        </>
    )
}

export default Cases