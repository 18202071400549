import React, { useState } from "react";
import Popup from "./Components/Popup";
import Footer from "./Layouts/Footer";
import Navbar from "./Layouts/Navbar";
import Home from "./Pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Routes, Route } from "react-router-dom";
import Services from "./Pages/Services";
import Industries from "./Pages/Industries";
import Blogs from "./Pages/Blogs";
import Carrers from "./Pages/Carrers";

const App = () => {
  const [initialState, setInitiailState] = useState(false);

  const handleOpen = () => {
    setInitiailState(true);
  };

  const handleClose = () => {
    setInitiailState(false);
  };

  return (
    <div className="tracking-wide">
      <ToastContainer
        position="top-right"
        autoClose={1200}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Navbar handleSwitch={handleOpen} />
      <Routes>
        <Route index element={<Home handleSwitch={handleOpen} />} />
        <Route
          path="/services"
          element={<Services handleSwitch={handleOpen} />}
        />
        <Route
          path="/industries"
          element={<Industries handleSwitch={handleOpen} />}
        />
         <Route
          path="/blogs"
          element={<Blogs handleSwitch={handleOpen} />}
        />
         <Route
          path="/carrers"
          element={<Carrers handleSwitch={handleOpen} />}
        />
      </Routes>
      <Footer />
      <Popup initialState={initialState} handleClose={handleClose} />
    </div>
  );
};

export default App;
