import React from 'react'
import WithOutBorder from './Buttons/WithOutBorder'

const CommonGS = ({ subheading,handleSwitch }) => {
    return (
        <div className="flex flex-col  gap-y-5 items-center">
            <button onClick={handleSwitch} className='rounded-full shadow-sm py-2.5 px-5 md:py-4 md:px-8 bg-[rgb(249,142,64)] text-white tracking-wider font-medium '>Get Started</button>
           <WithOutBorder title={subheading}/>
        </div>
    )
}

export default CommonGS