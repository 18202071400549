import React from "react";
import Responsive from "./Responsive";

const faqsItem = [
  {
    title: "What kind of custom applications does ZoopedAi offer?",
    description:
      "We offer a wide range of custom applications including websites, mobile apps, and software      solutions. We specialize in creating applications tailored to your specific needs and goals.",
  },
  {
    title: "How long does it take to develop a custom application?",
    description:
      "The time required depends on the complexity of the application and the requirements of the     project. Generally speaking, the process can take anywhere from a few weeks to a few months.",
  },
  {
    title: "Who owns the code of the custom application?",
    description:
      "When you use ZoopedAi to develop a custom application, you own the code.",
  },
  {
    title: "Do I need to have technical knowledge to use ZoopedAi?",
    description:
      "No, you don’t need to have any technical knowledge to use our services. Our product experts are       with you every step of the way to project manage your custom app build.",
  },
  {
    title: "What kind of support can I get from ZoopedAi?",
    description: "What kind of support can I get from ZoopedAi?",
  },
  {
    title: "What are the benefits of creating my own app?",
    description:
      "Creating your own app gives you complete control over the code, design, implementation and       performance. You can customize the app to fit your exact needs and preferences, without having to       pay any fees or revenue sharing to a third-party. Plus, you'll own the intellectual property rights,       meaning you can scale and grow your business without any limitations.",
  },
  {
    title: "How do I get started with creating my own app?",
    description:
      "The first step is to contact a software development agency with experience in app development.       They will be able to advise you on the best strategy and technology to use, as well as provide a free       quotation. After that, the development process can begin, with the app usually taking several weeks       to create.",
  },
  {
    title: "What will this cost?",
    description:
      "The cost of developing an app varies depending on the complexity and features of the app. Generally       speaking, it can range anywhere between a few thousand dollars to tens of thousands. It's important       to get a quote from a software development agency to get a more accurate figure.       ",
  },
  {
    title: "How can I ensure my app performs well?",
    description:
      "By working with a qualified software development agency that has experience in app development,       you can ensure that your app is built with the latest technology and standards. This will ensure       smooth performance and a good user experience.  ",
  },
  {
    title: "Is there any ongoing maintenance required?",
    description:
      "Yes, ongoing maintenance is essential to ensure the app performs optimally and is secure. Software       development agencies often offer maintenance and support packages that can be tailored to your       needs. This can include bug fixes, updates and feature additions, as well as regular security checks to       protect your data. ",
  },
  {
    title: "What type of apps do you build? ",
    description:
      "We build customised apps for a wide range of needs and industries, and provide unlimited       customisations so you can get exactly the app you desire.      ",
  },
  {
    title: "How quickly can you build my app?  ",
    description:
      "We are able to get your app built quickly and efficiently, with fast turnaround times and total       visibility on your project from beginning to end.   ",
  },
  {
    title: "Do I get a prototype of my app?   ",
    description:
      "Yes, when you work with us, we provide you with an app prototype that you can share with internal      teams and investors. ",
  },
  {
    title: "Who owns the code of the app?  ",
    description:
      "You own all of the code of the app, granting you access and control to make any future changes or      updates.",
  },
];

const Faqs = () => {
  return (
    <>
      <section
        id="faqs"
        className={`${Responsive} grid md:grid-cols-2 gap-y-5 `}
      >
        <h2 className="font-semibold text-3xl sm:text-4xl">FAQ's</h2>
        <div className="flex flex-col  gap-y-3 md:gap-y-5">
          {faqsItem.map((items, index) => {
            return (
              <details
                key={index}
                className="border-b border-slate-300 pb-2 md:pb-3"
              >
                <summary className="cursor-pointer hover:text-[rgb(249,142,64)] text-lg  md:text-xl tracking-wider font-medium">
                  {items.title}
                </summary>
                <p className="md:pt-4 pt-3 ">
                  Epcot is a theme park at Walt Disney World Resort featuring
                  exciting attractions, international pavilions, award-winning
                  fireworks and seasonal special events.
                </p>
              </details>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Faqs;
