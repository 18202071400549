import React from "react";
import CommonHero from "../Components/CommonHero";
import Responsive from "../Components/Responsive";

const Carrers = () => {
  return (
    <div>
      <CommonHero
        backImg={
          "https://images.unsplash.com/photo-1487528278747-ba99ed528ebc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
        }
        subTitle="Carrers for you"
      />
      <div
        className={`${Responsive} grid sm:grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4`}
      >
        {["", "", "", "", "", "", ""].map((item) => {
          return (
            <div
              href="#"
              className="sm:col-span-2 md:col-span-1 block group relative transition ease-out active:opacity-75 overflow-hidden"
            >
              <img
                src="https://images.unsplash.com/photo-1621905251918-48416bd8575a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
                alt="Product Image"
                className="transform transition ease-out group-hover:scale-110"
              />
              <div className="absolute inset-0 bg-black bg-opacity-25 transition ease-out group-hover:bg-opacity-0" />
              <div className="p-4 flex items-center justify-center absolute inset-0">
                <div className="py-3 px-4 bg-white bg-opacity-95 rounded-3xl text-sm font-semibold uppercase tracking-wide transition ease-out group-hover:text-white group-hover:bg-blue-600">
                  Carrer Title
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Carrers;
