import React, { useState } from "react";
import Responsive from "./Responsive";
import { MdOutlineDashboard } from "react-icons/md";
import { FaCheck } from "react-icons/fa";

const Features = ({ handleSwitch }) => {
  const [activeTab, setActiveTab] = useState(false);

  const checkItems = [
    "No tech knowledge needed",
    "AI means we can build more cost effectively, and at speed",
    "Your Product Expert is with you at every step",
    "No tech knowledge needed",
    "AI means we can build more cost effectively, and at speed",
    "Your Product Expert is with you at every step",
  ];
  return (
    <div div id="features">
      <div className="bg-[rgb(248,249,250)] lg:pb-48 mt-14">
        <div className={`${Responsive}`}>
          <div className="text-center font-semibold text-xl lg:text-3xl md:text-2xl">
            As featured in
          </div>

          <h3 className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl my-10 font-semibold text-center">
            Choose a solution to fit your needs
          </h3>
          {/* Tabs */}
          <div className="flex justify-center sm:gap-14 gap-10 md:gap-20">
            <span
              onClick={() => setActiveTab(false)}
              className={`px-2 ${
                !activeTab && "border-b-2 border-[rgb(249,142,64)]"
              } hover:border-b-2 font-medium cursor-pointer hover:border-[rgb(249,142,64)]`}
            >
              Create Apps
            </span>
            <span
              onClick={() => setActiveTab(true)}
              className={`px-2 ${
                activeTab && "border-b-2 border-[rgb(249,142,64)]"
              } hover:border-b-2 font-medium cursor-pointer hover:border-[rgb(249,142,64)]`}
            >
              Hosting & Support
            </span>
          </div>
        </div>
      </div>
      <div className={`md:relative lg:mb-80 ${Responsive}`}>
        <div
          className={` lg:absolute lg:-bottom-72 grid md:grid-cols-2 lg:grid-cols-3 gap-10`}
        >
          {(activeTab ? ["", ""] : ["", "", ""]).map((item, index) => {
            return (
              <div key={index} className="p-4 bg-white rounded shadow">
                <div className="flex gap-2 flex-col items-center justify-center">
                  <div className="text-4xl bg-orange-400 text-white p-2.5 rounded-lg text-center">
                    <MdOutlineDashboard />
                  </div>
                  <span className="text-lg text-orange-400">
                    Pre-packaged apps
                  </span>
                </div>

                <div className="text-center py-5 text-sm text-gray-500">
                  Online starter kits for small business (ecommerce, restaurant,
                  e-learning, gym, salon…)
                </div>

                <div className="flex items-center flex-col">
                  <h3 className="font-semibold">From £295</h3>
                  <div className="text-center text-sm text-gray-500">
                    £295 x24 monthly installments
                  </div>
                  <div
                    className="cursor-pointer bg-orange-400 hover:bg-orange-400 text-white py-3
                         rounded w-full text-center my-4"
                  >
                    {" "}
                    Get Started
                  </div>
                  <div className="text-center underline border-b-2 pb-4 w-full">
                    Find my business category
                  </div>
                  <div
                    className="py-5 flex flex-col gap-3
                        "
                  >
                    {checkItems.map((item, index) => {
                      return (
                        <div key={index} className="flex items-center">
                          <div
                            className="text-white flex justify-center items-center bg-black
                             rounded-full w-5 h-5 p-1.5
                              text-2xl"
                          >
                            <FaCheck />
                          </div>{" "}
                          <span className=" pl-3 text-sm">{item}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Features;
